export const Events = {
  VIEW_ITEM: "viewItem",
  ADD_TO_CART: "addToCart",
  REMOVE_FROM_CART: "removeFromCart",
  VIEW_CART: "viewCart",
  BEGIN_CHECKOUT: "beginCheckout",
  ADD_SHIPPING_INFO: "addShippingInfo",
  ADD_PAYMENT_INFO: "addPaymentInfo",
  PURCHASE: "purchase",
}
export const eventDispatcher = (eventName, bubbles = false, cancelable = true, composed = false) => {
  return (data = null, element = document) => {
    element.dispatchEvent(
      new CustomEvent(
        eventName,
        {
          bubbles,
          cancelable,
          composed,
          detail: data,
        }
      )
    );
  }
}

export const dispatchViewItemEvent = eventDispatcher(Events.VIEW_ITEM);
export const dispatchAddToCartEvent = eventDispatcher(Events.ADD_TO_CART);
export const dispatchRemoveFromCart = eventDispatcher(Events.REMOVE_FROM_CART);
export const dispatchViewCartEvent = eventDispatcher(Events.VIEW_CART);
export const dispatchBeginCheckoutEvent = eventDispatcher(Events.BEGIN_CHECKOUT);
export const dispatchAddShippingInfoEvent = eventDispatcher(Events.ADD_SHIPPING_INFO);
export const dispatchAddPaymentInfoEvent = eventDispatcher(Events.ADD_PAYMENT_INFO);
export const dispatchPurchaseEvent = eventDispatcher(Events.PURCHASE);